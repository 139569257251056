.begin-card {
  padding: 0px 20px;

  .begin-text {
    font-size: 24px;
    text-align: center;
    margin-top: 100px;
  }

  .page-title {
    padding: 0px 25px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
  }
}
