.question-card {
  .card-header {
    background-color: transparent;
    border: none;

    .category {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  .card-body {
    .question-text {
      border: 1px solid rgba(0, 0, 0, 0.125);
      padding: 80px 30px;
      font-size: 24px;
      text-align: center;
      word-break: break-all;
    }

    fieldset legend {
      font-weight: bold;
    }
  }
}
